<template>
  <!-- counter -->
  <div>
    <v-container grid-list-lg class="carder" fluid>
      <v-tabs
        centered
        color="blue darken-1"
        background-color="transparent"
        grow
      >
        <v-tab
          v-for="(value, index) in navigation"
          :key="index"
          :to="value.path"
          >{{ value.title }}</v-tab
        >
      </v-tabs>
      <div v-for="(value, index) in navigation" :key="index" class="w-100">
        <div v-if="Route === value.name">
          <component :is="value.component" />
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Overall",

  data() {
    return {
      navigation: [
        {
          name: "product",
          path: "/impersonate/overall/product",
          title: "Products",
          component: () => import("./components/Products"),
        },
        // {
        //   name: "outlets",
        //   path: "/impersonate/overall/outlets",
        //   title: "Outlets",
        //   component: () => import("./components/Outlets"),
        // },
        {
          name: "tags",
          path: "/impersonate/overall/tags",
          title: "Tags",
          component: () => import("./components/Tags"),
        },
        {
          name: "vouchers",
          path: "/impersonate/overall/vouchers",
          title: "Vouchers",
          component: () => import("./components/Vouchers"),
        },
        {
          name: "manage-orders",
          path: "/impersonate/overall/manage-orders",
          title: "Manage Orders",
          component: () => import("./components/ManageOrders"),
        },
        {
          name: "inventory",
          path: "/impersonate/overall/inventory",
          title: "Inventory",
          component: () => import("./components/Inventory"),
        },
        {
          name: "order-summary",
          path: "/impersonate/overall/order-summary",
          title: "Order Summary",
          component: () => import("./components/OrderSummary"),
        },
        // {
        //   name: "customers",
        //   path: "/impersonate/overall/customers",
        //   title: "Customers",
        //   component: () => import("./components/Customers"),
        // },
        {
          name: "vendors",
          path: "/impersonate/overall/vendors",
          title: "Vendors",
          component: () => import("./components/Vendors"),
        },
        {
          name: "agents",
          path: "/impersonate/overall/agents",
          title: "Agents",
          component: () => import("./components/Agents"),
        },
      ],
      Route: this.$route.params.param,
      // {
      //   name: "Melissa",
      //   store: "Miko cafe",
      // },
    };
  },

  watch: {
    $route: "updateRoute",
  },

  methods: {
    updateRoute() {
      this.Route = this.$route.params.param;
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}

.pad {
  margin-top: 3px;
}

.w-75 {
  width: 75%;
}
</style>
